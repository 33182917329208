<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-lg-5 position-fixed">
          Membership
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="6"
        md="12"
        class="p-lg-5"
      >
        <div class="brand-img">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="6"
        md="12"
        class="d-flex align-items-center auth-bg px-lg-2 px-md-0 px-sm-0 p-lg-5 p-md-0 p-sm-0"
      >
        <b-col
          sm="8"
          md="11"
          lg="12"
          class="px-xl-2 px-md-0 px-sm-0 mx-lg-auto mx-md-2 mx-sm-1 mt-md-3"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            <div class="d-lg-flex d-md-flex justify-content-between card-header-section">
              <span>PAVRISK Membership</span>
              <span
                class="font-small-3"
              >Step {{ current_page }} of {{ total_pages }}</span>
            </div>
          </b-card-title>
          <validation-observer
            ref="registerFirstForm"
            #default="{ invalid }"
          >
            <b-form
              v-if="current_page === 1"
              class="auth-register-form mt-2"
              @submit.prevent="validationFirstForm"
            >
              <b-row>
                <b-col
                  lg="12"
                  md="12"
                  sm="12"
                >
                  <b-form-group
                    label="Select Type of Content"
                    label-for="content"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Role"
                      rules="required"
                    >
                      <b-form-select
                        v-model="content_type"
                        :options="content_types"
                        size="md"
                        @change="updateContentTypeSelection"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="12"
                  md="12"
                  sm="12"
                >
                  <b-form-group
                    label="Select Your Role"
                    label-for="type"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="type"
                      rules="required"
                    >
                      <b-form-group>
                        <b-form-select
                          v-model="member_role_type"
                          :options="content_roles"
                          size="md"
                          @change="updateContentRoleSelection"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="d-flex flex-column">
                <b-form-group>
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="status"
                    name="checkbox-1"
                  >
                    I agree to
                    <b-link>privacy policy & terms</b-link>
                  </b-form-checkbox>
                </b-form-group>
                <b-button
                  variant="primary"
                  block
                  type="submit"
                  :disabled="invalid || isLoading"
                >
                  {{ !isLoading ? "Continue" : "Please wait..." }}
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                </b-button>
              </div>
            </b-form>
          </validation-observer>
          <validation-observer
            ref="registerSecondForm"
            #default="{ invalid }"
          >
            <b-form
              v-if="current_page === 2"
              class="auth-register-form mt-2"
              @submit.prevent="validationSecondForm"
            >
              <b-row>
                <b-col
                  lg="6"
                  md="6"
                  sm="12"
                >
                  <h5>
                    Selected Type of Content:
                  </h5>
                  <h4 class="mx-2 text-primary">
                    {{ selected_content_type }}
                  </h4>
                </b-col>
                <b-col
                  lg="6"
                  md="6"
                  sm="12"
                >
                  <h5>
                    Selected Content Role:
                  </h5>
                  <h4 class="mx-2 text-primary">
                    {{ selected_content_role }}
                  </h4>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  lg="12"
                  md="12"
                  sm="12"
                >
                  <b-form-group
                    label="Select Type"
                    label-for="type"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="type"
                      rules="required"
                    >
                      <b-form-group>
                        <b-form-select
                          v-model="member_type"
                          :options="member_types"
                          size="md"
                          @change="updateMemberTypeSelection"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Surname or Corporate/Label Name -->
                <b-col
                  v-if="member_type_in_words !== 'Corporate/Record Label' && member_type_in_words !== 'Company'"
                  lg="6"
                  sm="12"
                >
                  <!-- firstname -->
                  <b-form-group
                    v-if="member_type_in_words !== 'Corporate/Record Label' && member_type_in_words !== 'Company'"
                    :label="
                      member_type_in_words === 'Minor'
                        ? 'Guardian\'s Surname'
                        : 'Surname'
                    "
                    label-for="surname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Surname"
                      rules="required"
                    >
                      <b-form-input
                        id="surname"
                        v-model="surname"
                        :state="errors.length > 0 ? false : null"
                        name="register-surnname"
                        placeholder="Doe"
                        type="text"
                        onkeydown="return /[a-z]/i.test(event.key)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Other Names -->
                <b-col
                  v-if="member_type_in_words !== 'Corporate/Record Label' && member_type_in_words !== 'Company'"
                  lg="6"
                  sm="12"
                >
                  <!-- lastname -->
                  <b-form-group
                    v-if="member_type_in_words !== 'Corporate/Record Label' && member_type_in_words !== 'Company'"
                    :label="
                      member_type_in_words === 'Minor'
                        ? 'Guardian\'s Other Names'
                        : 'Other Names'
                    "
                    label-for="othernames"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Other Names"
                      rules="required"
                    >
                      <b-form-input
                        id="other_names"
                        v-model="other_names"
                        :state="errors.length > 0 ? false : null"
                        name="Other Names"
                        placeholder="John"
                        type="text"
                        onkeydown="return /[a-z]/i.test(event.key)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Gender -->
                <b-col
                  v-if="member_type_in_words !== 'Corporate/Record Label' && member_type_in_words !== 'Company'"
                  lg="12"
                  sm="12"
                  class="mb-1"
                >
                  <b-row>
                    <b-col
                      lg="4"
                      sm="12"
                    >
                      Select Gender:
                    </b-col>
                    <b-col
                      lg="8"
                      sm="12"
                    >
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Gender"
                          rules="required"
                          vid="gender"
                        >
                          <div class="d-flex">
                            <b-form-radio
                              v-for="gender in gender_options"
                              :key="gender"
                              v-model="member_gender"
                              class="mx-2"
                              plain
                              :value="gender"
                            >
                              {{ gender }}
                            </b-form-radio>
                          </div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <!-- Record Label Business Name -->
                <b-col
                  v-if="member_type_in_words === 'Corporate/Record Label' || member_type_in_words === 'Company'"
                  lg="6"
                  sm="12"
                >
                  <!-- Business Name -->
                  <b-form-group
                    v-if="member_type_in_words === 'Corporate/Record Label' || member_type_in_words === 'Company'"
                    label="Business Name"
                    label-for="businessname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Business Name"
                      rules="required"
                    >
                      <b-form-input
                        id="business-name"
                        v-model="business_name"
                        :state="errors.length > 0 ? false : null"
                        name="business-name"
                        placeholder="Enter Business Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Record Label Registration Number or ID Number -->
                <b-col
                  v-if="member_type_in_words !== 'Corporate/Record Label' && member_type_in_words !== 'Company'"
                  lg="6"
                  sm="12"
                >
                  <!-- id_number -->
                  <b-form-group
                    v-if="member_type_in_words !== 'Corporate/Record Label' && member_type_in_words !== 'Company'"
                    :label="
                      member_type_in_words === 'Minor'
                        ? 'Guardian\'s ID No. or Passport No.'
                        : 'ID Number or Passport Number'
                    "
                    label-for="id_number"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="ID Number"
                      rules="required|validate-id-number"
                      vid="id_number"
                    >
                      <b-form-input
                        id="id_number"
                        v-model="id_number"
                        :state="errors.length > 0 ? false : null"
                        name="ID Number"
                        placeholder="*********"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Record Label Business Registration Number -->
                <b-col
                  v-if="member_type_in_words === 'Corporate/Record Label' || member_type_in_words === 'Company'"
                  lg="6"
                  sm="12"
                >
                  <!-- business_registration_number -->
                  <b-form-group
                    v-if="member_type_in_words === 'Corporate/Record Label' || member_type_in_words === 'Company'"
                    label="Business Registration Number"
                    label-for="business_registration_number"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Business Registration Number"
                      rules="required"
                      vid="business_registration_number"
                    >
                      <b-form-input
                        id="business_registration_number"
                        v-model="business_registration_number"
                        :state="errors.length > 0 ? false : null"
                        name="business-registration-number"
                        placeholder="Enter Business Registration Number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- KRA PIN -->
                <b-col
                  lg="6"
                  sm="12"
                >
                  <!-- kra_pin -->
                  <b-form-group
                    v-if="
                      member_type_in_words === 'Adult' ||
                        member_type_in_words === 'Deceased Person' ||
                        member_type_in_words === ''
                    "
                    label="KRA PIN"
                    label-for="kra_pin"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="KRA PIN"
                      rules="required|validate-kra-pin"
                      vid="kra_pin"
                    >
                      <b-form-input
                        id="kra_pin"
                        v-model="kra_pin"
                        :state="errors.length > 0 ? false : null"
                        name="kra-pin"
                        placeholder="*********"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    v-if="member_type_in_words === 'Corporate/Record Label' || member_type_in_words === 'Company'"
                    label="Business's KRA PIN"
                    label-for="kra_pin"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="KRA PIN"
                      rules="required|validate-kra-pin"
                      vid="kra_pin"
                    >
                      <b-form-input
                        id="business_kra_pin"
                        v-model="kra_pin"
                        :state="errors.length > 0 ? false : null"
                        name="kra-pin"
                        placeholder="*********"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    v-if="member_type_in_words === 'Minor'"
                    label="Guardian's KRA PIN"
                    label-for="kra_pin"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="KRA PIN"
                      rules="required|validate-kra-pin"
                      vid="kra_pin"
                    >
                      <b-form-input
                        id="guardian_kra_pin"
                        v-model="kra_pin"
                        :state="errors.length > 0 ? false : null"
                        name="kra-pin"
                        placeholder="*********"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Email -->
                <b-col
                  lg="6"
                  sm="12"
                >
                  <!-- email -->
                  <b-form-group
                    v-if="
                      member_type_in_words === 'Adult' ||
                        member_type_in_words === 'Minor' ||
                        member_type_in_words === 'Deceased Person' ||
                        member_type_in_words === ''
                    "
                    :label="
                      member_type_in_words === 'Minor'
                        ? 'Guardian\'s Email'
                        : 'Email'
                    "
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                      vid="email"
                    >
                      <b-form-input
                        id="adult_email"
                        v-model="regEmail"
                        :state="errors.length > 0 ? false : null"
                        name="register-email"
                        placeholder="john@example.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    v-if="member_type_in_words === 'Corporate/Record Label' || member_type_in_words === 'Company'"
                    label="Business Email"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                      vid="email"
                    >
                      <b-form-input
                        id="business_email"
                        v-model="regEmail"
                        :state="errors.length > 0 ? false : null"
                        name="register-email"
                        placeholder="john@example.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Phone Number -->
                <b-col
                  lg="6"
                  sm="12"
                >
                  <!-- Phone Number -->
                  <b-form-group
                    v-if="
                      member_type_in_words === 'Adult' ||
                        member_type_in_words === 'Minor' ||
                        member_type_in_words === 'Deceased Person' ||
                        member_type_in_words === ''
                    "
                    :label="
                      member_type_in_words === 'Minor'
                        ? 'Guardian\'s Phone Number'
                        : 'Phone Number'
                    "
                    label-for="phone_number"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="phone_number"
                      rules="required"
                      vid="phone_number"
                    >
                      <b-form-input
                        id="adult_phone_number"
                        v-model="phone_number"
                        :state="errors.length > 0 ? false : null"
                        name="phone-number"
                        placeholder="07000000"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    v-if="member_type_in_words === 'Corporate/Record Label' || member_type_in_words === 'Company'"
                    label="Business Phone Number"
                    label-for="phone_number"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="phone_number"
                      rules="required"
                      vid="phone_number"
                    >
                      <b-form-input
                        id="business_phone_number"
                        v-model="phone_number"
                        :state="errors.length > 0 ? false : null"
                        name="phone-number"
                        placeholder="07000000"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="d-flex justify-content-between">
                <b-button
                  variant="secondary"
                  @click="goToPrevPage"
                >
                  Previous
                </b-button>
                <b-button
                  v-if="total_pages === 4 || total_pages === 3"
                  variant="primary"
                  type="submit"
                  :disabled="invalid || isLoading"
                >
                  {{ !isLoading ? "Continue" : "Please wait..." }}
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                </b-button>
                <!-- submit button -->
                <b-button
                  v-if="total_pages === 2"
                  variant="primary"
                  type="submit"
                  :disabled="invalid || isLoading"
                >
                  {{ !isLoading ? "Sign Up" : "Please wait..." }}
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                </b-button>
              </div>
            </b-form>
          </validation-observer>

          <!-- form -->
          <validation-observer
            ref="registerThirdForm"
            #default="{ invalid }"
          >
            <b-form
              v-if="current_page === 3"
              class="auth-register-form mt-2"
              @submit.prevent="validationThirdForm"
            >
              <b-row>
                <!-- Minor Name -->
                <b-col
                  v-if="member_type_in_words === 'Minor'"
                  lg="12"
                  sm="12"
                >
                  <!-- Minor Name -->
                  <b-form-group
                    v-if="member_type_in_words === 'Minor'"
                    label="Minor's Name"
                    label-for="child_name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Minor's Name"
                      rules="required"
                    >
                      <b-form-input
                        id="child_name"
                        v-model="child_name"
                        :state="errors.length > 0 ? false : null"
                        name="Minor's Name"
                        type="text"
                        onkeydown="return /[a-z]/i.test(event.key)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Upload Minor Birth Certificate -->
                <b-col
                  v-if="member_type_in_words === 'Minor'"
                  lg="12"
                  sm="12"
                >
                  <!-- Minor Birth Certificate -->
                  <b-form-group
                    v-if="member_type_in_words === 'Minor'"
                    label="Minor's Birth Certificate"
                    label-for="child_birth_certificate"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Minor's Birth Certificate"
                      rules="required"
                    >
                      <b-form-file
                        id="child_birth_certificate"
                        v-model="child_birth_certificate"
                        :state="errors.length > 0 ? false : null"
                        name="Minor's Birth Certificate"
                        accept=".pdf"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      />
                      <div class="mt-1">
                        Selected file:
                        <strong>{{
                          child_birth_certificate
                            ? child_birth_certificate.name
                            : ""
                        }}</strong>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-show="show_child_certificate_too_large_error"
                        class="text-danger"
                      >File Too Large (Max 5MB)</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Minor Age -->
                <b-col
                  v-if="member_type_in_words === 'Minor'"
                  lg="6"
                  sm="12"
                >
                  <!-- Age -->
                  <b-form-group
                    v-if="member_type_in_words === 'Minor'"
                    label="Minor's Age"
                    label-for="age"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Age"
                      rules="required"
                    >
                      <b-form-spinbutton
                        id="age"
                        v-model="age"
                        min="1"
                        max="18"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Minor-Guardian Relationship -->
                <b-col
                  v-if="member_type_in_words === 'Minor'"
                  lg="6"
                  sm="12"
                >
                  <!-- Minor Relationship -->
                  <b-form-group
                    v-if="member_type_in_words === 'Minor'"
                    label="Enter Relationship To Minor"
                    label-for="child_relationship"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Minor Guardian Relationship"
                      rules="required"
                    >
                      <b-form-input
                        id="child_relationship"
                        v-model="child_relationship"
                        placeholder="e.g Mother, Father, Brother, Cousin"
                        type="text"
                        onkeydown="return /[a-z]/i.test(event.key)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- ID Copy -->
                <b-col
                  v-if="member_type_in_words !== 'Corporate/Record Label' && member_type_in_words !== 'Company'"
                  cols="12"
                >
                  <b-form-group
                    :label="
                      member_type_in_words === 'Minor'
                        ? 'Upload Guardian\'s ID or Passport'
                        : 'Upload Your ID or Passport'
                    "
                    label-for="id_upload"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="National ID or Passport"
                      rules="required"
                    >
                      <b-form-file
                        id="identification_document"
                        v-model="id_file"
                        :state="errors.length > 0 ? false : null"
                        name="Identification Document"
                        accept=".pdf"
                      />
                      <div class="mt-1">
                        Selected file:
                        <strong>{{ id_file ? id_file.name : "" }}</strong>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-show="show_id_file_too_large_error"
                        class="text-danger"
                      >File Too Large (Max 5MB)</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Upload KRA PIN Certificate -->
                <b-col cols="12">
                  <b-form-group
                    :label="
                      member_type_in_words === 'Minor'
                        ? 'Upload Guardian\'s KRA PIN Certificate'
                        : 'Upload KRA PIN Certificate'
                    "
                    label-for="kra_certificate"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="KRA Certificate"
                      rules="required"
                      vid="kra_cerfificate"
                    >
                      <b-form-file
                        id="kra_certificate"
                        v-model="kra_certificate"
                        :state="errors.length > 0 ? false : null"
                        name="kra_certificate"
                        accept=".pdf"
                      />
                      <div class="mt-1">
                        Selected file:
                        <strong>{{
                          kra_certificate ? kra_certificate.name : ""
                        }}</strong>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-show="show_kra_certificate_too_large_error"
                        class="text-danger"
                      >File Too Large (Max 5MB)</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Upload Business Permit -->
                <b-col
                  v-if="member_type_in_words === 'Corporate/Record Label' || member_type_in_words === 'Company'"
                  cols="12"
                >
                  <b-form-group
                    label="Upload Business Permit"
                    label-for="business_registration_certificate"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Business Permit"
                      rules="required"
                      vid="business_registration_certificate"
                    >
                      <b-form-file
                        id="business_registration_certificate"
                        v-model="business_registration_certificate"
                        :state="errors.length > 0 ? false : null"
                        name="business_registration_certificate"
                        accept=".pdf"
                      />
                      <div class="mt-1">
                        Selected file:
                        <strong>{{
                          business_registration_certificate ? business_registration_certificate.name : ""
                        }}</strong>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-show="show_business_registration_certificate_too_large_error"
                        class="text-danger"
                      >File Too Large (Max 5MB)</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Deceased Person name -->
                <b-col
                  v-if="member_type_in_words === 'Deceased Person'"
                  lg="12"
                  sm="12"
                >
                  <b-form-group
                    v-if="member_type_in_words === 'Deceased Person'"
                    label="Enter Deceased Person's Name"
                    label-for="deceased_person_name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Deceased Person's Name"
                      rules="required"
                    >
                      <b-form-input
                        id="deceased_person_name"
                        v-model="deceased_person_name"
                        type="text"
                        onkeydown="return /[a-z]/i.test(event.key)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Upload Avatar or Logo -->
                <b-col cols="12">
                  <b-form-group
                    :label="labels.passport_photo"
                    label-for="avatar"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="avatar"
                      rules="required"
                    >
                      <b-form-file
                        id="avatar"
                        v-model="avatar"
                        :state="errors.length > 0 ? false : null"
                        name="avatar"
                        accept=".jpg,.jpeg,.png"
                      />
                      <div class="mt-1">
                        Selected file:
                        <strong>{{ avatar ? avatar.name : "" }}</strong>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Stage Name -->
                <!-- <b-col
                  v-if="
                    member_type_in_words === 'Adult' ||
                      member_type_in_words === 'Child' ||
                      member_type_in_words === ''
                  "
                  lg="5"
                  sm="12"
                >
                  {{
                    member_type_in_words === "Child"
                      ? "Is the child a Performer?"
                      : "Are you a Performer?"
                  }}
                </b-col> -->

                <!-- Select If Performer -->
                <!-- <b-col
                  v-if="
                    member_type_in_words === 'Adult' ||
                      member_type_in_words === 'Child' ||
                      member_type_in_words === ''
                  "
                  lg="7"
                  sm="12"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Is Performer"
                      rules="required"
                      vid="stage_name"
                    >
                      <div class="d-flex">
                        <b-form-radio
                          v-model="is_performer"
                          class="mx-2"
                          plain
                          value="true"
                        >
                          Yes
                        </b-form-radio>
                        <b-form-radio
                          v-model="is_performer"
                          class="mx-2"
                          plain
                          value="false"
                        >
                          No
                        </b-form-radio>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->

                <!-- Enter Stage Name -->
                <b-col
                  lg="12"
                  sm="12"
                >
                  <b-form-group
                    v-if="
                      show_stage_name_input &&
                        (member_type_in_words === 'Adult' ||
                          member_type_in_words === 'Minor' ||
                          member_type_in_words === '')
                    "
                    :label="labels.stage_name"
                    label-for="stage_name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Stage Name"
                      rules="required"
                      vid="stage_name"
                    >
                      <b-form-input
                        id="stage_name"
                        v-model="stage_name"
                        :state="errors.length > 0 ? false : null"
                        name="stage-name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Deceased Person Death Certificate -->
                <b-col
                  v-if="member_type_in_words === 'Deceased Person'"
                  cols="12"
                >
                  <b-form-group
                    label="Deceased Person's Death Certificate"
                    label-for="death_certificate"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="death certificate"
                      rules="required"
                    >
                      <b-form-file
                        id="death_certificate"
                        v-model="deceased_death_certificate"
                        :state="errors.length > 0 ? false : null"
                        name="death certificate"
                        accept=".pdf"
                      />
                      <div class="mt-1">
                        Selected file:
                        <strong>{{
                          deceased_death_certificate
                            ? deceased_death_certificate.name
                            : ""
                        }}</strong>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-show="show_death_certificate_too_large_error"
                        class="text-danger"
                      >File Too Large (Max 5MB)</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Deceased Person Letter of Administration -->
                <b-col
                  v-if="member_type_in_words === 'Deceased Person'"
                  cols="12"
                >
                  <b-form-group
                    label="Letter of Administration"
                    label-for="letter_of_administration"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="letter of administration"
                      rules="required"
                    >
                      <b-form-file
                        id="letter_of_administration"
                        v-model="deceased_letter_of_administration"
                        :state="errors.length > 0 ? false : null"
                        name="letter of administration"
                        accept=".pdf"
                      />
                      <div class="mt-1">
                        Selected file:
                        <strong>{{
                          deceased_letter_of_administration
                            ? deceased_letter_of_administration.name
                            : ""
                        }}</strong>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-show="show_letter_of_administration_too_large_error"
                        class="text-danger"
                      >File Too Large (Max 5MB)</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Deceased Person Succession Letter -->
                <b-col
                  v-if="member_type_in_words === 'Deceased Person'"
                  cols="12"
                >
                  <b-form-group
                    label="Succession Letter"
                    label-for="succession_letter"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Succession Letter"
                      rules="required"
                    >
                      <b-form-file
                        id="succession_letter"
                        v-model="deceased_succession_letter"
                        :state="errors.length > 0 ? false : null"
                        name="succession letter"
                        accept=".pdf"
                      />
                      <div class="mt-1">
                        Selected file:
                        <strong>{{
                          deceased_succession_letter
                            ? deceased_succession_letter.name
                            : ""
                        }}</strong>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-show="show_succession_letter_too_large_error"
                        class="text-danger"
                      >File Too Large (Max 5MB)</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Password and Password Confirmation -->
                <b-col cols="12">
                  <b-row>
                    <b-col
                      lg="6"
                      sm="12"
                    >
                      <b-form-group
                        label="Password"
                        label-for="password"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="password"
                          rules="required"
                        >
                          <b-input-group
                            class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                          >
                            <b-form-input
                              id="password"
                              v-model="password"
                              :type="passwordFieldType"
                              :state="errors.length > 0 ? false : null"
                              class="form-control-merge"
                              name="register-password"
                              placeholder="············"
                            />
                            <b-input-group-append is-text>
                              <feather-icon
                                :icon="passwordToggleIcon"
                                class="cursor-pointer"
                                @click="togglePasswordVisibility"
                              />
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col
                      lg="6"
                      sm="12"
                    >
                      <!-- password confirmation -->
                      <b-form-group
                        label="Confirm Password"
                        label-for="password_confirmation"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="password_confirmation"
                          rules="required"
                        >
                          <b-input-group
                            class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                          >
                            <b-form-input
                              id="password_confirmation"
                              v-model="password_confirmation"
                              :type="passwordFieldType"
                              :state="errors.length > 0 ? false : null"
                              class="form-control-merge"
                              name="register-password"
                              placeholder="············"
                            />
                            <b-input-group-append is-text>
                              <feather-icon
                                :icon="passwordToggleIcon"
                                class="cursor-pointer"
                                @click="togglePasswordVisibility"
                              />
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <div class="d-flex justify-content-between">
                <b-button
                  variant="secondary"
                  @click="goToPrevPage"
                >
                  Previous
                </b-button>
                <b-button
                  v-if="total_pages === 4"
                  variant="primary"
                  type="submit"
                  :disabled="invalid || isLoading"
                >
                  {{ !isLoading ? "Continue" : "Please wait..." }}
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                </b-button>
                <!-- submit button -->
                <b-button
                  v-if="total_pages === 3"
                  variant="primary"
                  type="submit"
                  :disabled="invalid || isLoading"
                >
                  {{ !isLoading ? "Sign Up" : "Please wait..." }}
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                </b-button>
              </div>
            </b-form>
          </validation-observer>
          <validation-observer
            #default="{ invalid }"
            refs="validateFourthForm"
          >
            <b-form
              v-if="current_page === 4"
              class="auth-register-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-row>
                <!-- Upload Director Details -->
                <b-col
                  v-if="member_type_in_words === 'Corporate/Record Label' || member_type_in_words === 'Company'"
                  cols="12"
                >
                  <b-row>
                    <b-col cols="6">
                      <h5>Director Details</h5>
                    </b-col>
                    <b-col cols="6">
                      <b-button
                        size="sm"
                        class="float-right"
                        @click="addDirector"
                      >
                        Add Director
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row
                    v-for="counter in partners_counter"
                    :key="counter"
                  >
                    <b-col cols="12">
                      <h6>{{ counter }}{{ nth(counter) }} Director</h6>
                    </b-col>
                    <b-col
                      lg="6"
                      sm="12"
                    >
                      <b-form-group
                        label="Surname"
                        :label-for="counter + '-first-name'"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            counter + '' + nth(counter) + ' Director\'s Surname'
                          "
                          rules="required"
                        >
                          <b-form-input
                            :id="counter + '-director-first-name'"
                            v-model="partners_first_name[counter - 1]"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            onkeydown="return /[a-z]/i.test(event.key)"
                            :name="
                              counter +
                                '' +
                                nth(counter) +
                                ' Director\'s First Name'
                            "
                            :placeholder="
                              'Enter ' +
                                counter +
                                '' +
                                nth(counter) +
                                ' Director\'s First Name'
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      lg="6"
                      sm="12"
                    >
                      <b-form-group
                        label="Other Names"
                        :label-for="counter + '-last-name'"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            counter +
                              '' +
                              nth(counter) +
                              ' Director\'s Other Names'
                          "
                          rules="required"
                        >
                          <b-form-input
                            :id="counter + '-director-last-name'"
                            v-model="partners_last_name[counter - 1]"
                            :state="errors.length > 0 ? false : null"
                            :name="counter + '-director-last-name'"
                            type="text"
                            onkeydown="return /[a-z]/i.test(event.key)"
                            :placeholder="
                              'Enter ' +
                                counter +
                                '' +
                                nth(counter) +
                                ' Director\'s  Other Names'
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      lg="6"
                      sm="12"
                    >
                      <b-form-group
                        label="ID Number"
                        :label-for="counter + '-id-number'"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            counter +
                              '' +
                              nth(counter) +
                              ' Director\'s ID Number'
                          "
                          rules="required|validate-id-number"
                        >
                          <b-form-input
                            :id="counter + '-director-id-number'"
                            v-model="partners_id_number[counter - 1]"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            :name="
                              counter +
                                '' +
                                nth(counter) +
                                ' Director\'s ID Number'
                            "
                            :placeholder="
                              'Enter ' +
                                counter +
                                '' +
                                nth(counter) +
                                ' Director\'s ID Number'
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      lg="6"
                      sm="12"
                    >
                      <b-form-group
                        label="Phone Number"
                        :label-for="counter + '-phone-number'"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            counter +
                              '' +
                              nth(counter) +
                              ' Director\'s Phone Number'
                          "
                          rules="required"
                        >
                          <b-form-input
                            :id="counter + '-director-phone-number'"
                            v-model="partners_phone_number[counter - 1]"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            :name="
                              counter +
                                '' +
                                nth(counter) +
                                ' Director\'s Phone Number'
                            "
                            :placeholder="
                              'Enter ' +
                                counter +
                                '' +
                                nth(counter) +
                                ' Director Phone Number'
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      lg="6"
                      sm="12"
                    >
                      <b-form-group
                        label="Email"
                        :label-for="counter + '-email'"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            counter + '' + nth(counter) + 'Director\'s Email'
                          "
                          rules="required"
                        >
                          <b-form-input
                            :id="counter + '-director-email'"
                            v-model="partners_email[counter - 1]"
                            :state="errors.length > 0 ? false : null"
                            type="email"
                            :name="
                              counter + '' + nth(counter) + ' Director\'s Email'
                            "
                            :placeholder="
                              'Enter ' +
                                counter +
                                '' +
                                nth(counter) +
                                ' Director\'s Email'
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      lg="6"
                      sm="12"
                    >
                      <b-form-group
                        label="Shares Held"
                        :label-for="counter + '-shares'"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            counter + '' + nth(counter) + ' Director\'s Shares'
                          "
                          rules="required"
                        >
                          <b-form-input
                            :id="counter + '-director-shares'"
                            v-model="partners_shares[counter - 1]"
                            :state="errors.length > 0 ? false : null"
                            :name="
                              counter +
                                '' +
                                nth(counter) +
                                ' Director\'s Shares'
                            "
                            :placeholder="
                              'Enter ' +
                                counter +
                                '' +
                                nth(counter) +
                                ' Director\'s Shares'
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <div class="d-flex justify-content-between">
                <b-button
                  variant="secondary"
                  @click="goToPrevPage"
                >
                  Previous
                </b-button>
                <!-- submit button -->
                <b-button
                  variant="primary"
                  type="submit"
                  :disabled="invalid || isLoading"
                >
                  {{ !isLoading ? "Sign Up" : "Please wait..." }}
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                </b-button>
              </div>
            </b-form>
          </validation-observer>
          <b-card-text class="text-center mt-2">
            <span>Already have an account? </span>
            <b-link :to="{ name: 'auth-login' }">
              <span>Sign in instead</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BRow,
  BCol,
  BImg,
  BButton,
  BFormInput,
  BFormGroup,
  BFormFile,
  BInputGroup,
  BFormSelect,
  BInputGroupAppend,
  BFormCheckbox,
  BSpinner,
  BFormSpinbutton,
  BFormRadio,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'

extend('validate-kra-pin', {
  validate: value => {
    let pass = true
    const onlyLetters = /^[a-zA-Z]+$/
    // eslint-disable-next-line camelcase
    const first_is_letter = value.charAt(0)
    // eslint-disable-next-line camelcase
    const last_is_letter = value.charAt(value.length - 1)
    // Check length
    if (value.length < 11 || value.length > 11) {
      pass = false
    } else {
      // Check if first character is letter
      // eslint-disable-next-line camelcase, no-lonely-if
      if (onlyLetters.test(first_is_letter)) {
        pass = true
        // Check if last character is letter
        if (onlyLetters.test(last_is_letter)) {
          pass = true
        } else {
          pass = false
        }
      } else {
        pass = false
      }
    }
    return pass
  },
})

extend('validate-id-number', {
  validate: value => {
    let pass = true
    if (value.length < 6 || value.length > 10) {
      pass = false
    }
    return pass
  },
})

export default {
  components: {
    // BSV
    BRow,
    BImg,
    BCol,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormSelect,
    BFormSpinbutton,
    BFormGroup,
    BInputGroup,
    BFormFile,
    BInputGroupAppend,
    BFormCheckbox,
    BSpinner,
    BFormRadio,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      member_role_types: [],
      member_role_type: '',
      is_performer: false,
      show_stage_name_input: false,
      gender_options: ['Male', 'Female'],
      content_types: [],
      content_type: '',
      content_roles: [],
      content_role: '',
      member_types: [],
      member_types_all: [],
      member_type: '',
      member_type_in_words: 'Adult',
      id_number: '',
      surname: '',
      other_names: '',
      member_gender: '',
      business_name: '',
      business_registration_number: '',
      kra_certificate: null,
      id_file: null,
      regEmail: '',
      phone_number: '',
      kra_pin: '',
      business_registration_certificate: null,
      password: '',
      password_confirmation: '',
      age: '',
      child_name: '',
      child_relationship: '',
      child_birth_certificate: null,
      deceased_person_name: '',
      deceased_death_certificate: null,
      deceased_letter_of_administration: null,
      deceased_succession_letter: null,
      stage_name: '',
      partners_counter: 0,
      partners: [],
      partners_first_name: [],
      partners_last_name: [],
      partners_id_number: [],
      partners_phone_number: [],
      partners_email: [],
      partners_shares: [],
      avatar: null,
      status: '',
      isLoading: false,

      show_id_file_too_large_error: false,
      show_kra_certificate_too_large_error: false,
      show_business_registration_certificate_too_large_error: false,
      show_child_certificate_too_large_error: false,
      show_death_certificate_too_large_error: false,
      show_letter_of_administration_too_large_error: false,
      show_succession_letter_too_large_error: false,

      // validation rules
      required,
      email,
      // Page Switcher
      current_page: 1,
      total_pages: 3,
      // sideImg: require('@/assets/images/pages/register-v2.svg'),
      // eslint-disable-next-line global-require
      sideImg: require('@/assets/pavrisk-logo.png'),
      labels: {
        passport_photo: 'Upload Your Passport Photo',
        stage_name: 'Enter your stage name',
      },

      selected_content_type: '',
      selected_content_role: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties,global-require
        this.sideImg = require('@/assets/pavrisk-logo.png')
        // this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  watch: {
    regEmail(newEmail) {
      if (this.member_type !== 3) {
        this.$http
          .post('/membership/auth/registration/email/check', {
            email: newEmail,
          })
          .then(() => {})
          .catch(error => {
            console.log(error)
            this.$refs.registerSecondForm.setErrors(error.response.data.data)
          })
      }
    },
    phone_number(newPhoneNumber) {
      if (this.member_type !== 3) {
        this.$http
          .post('/membership/auth/registration/phone/check', {
            phone_number: newPhoneNumber,
          })
          .then(() => {})
          .catch(error => {
            this.$refs.registerSecondForm.setErrors(error.response.data.data)
          })
      }
    },
    kra_pin(newKraPin) {
      if (this.member_type !== 3) {
        this.$http
          .post('/membership/auth/registration/kra-pin/check', {
            kra_pin: newKraPin,
          })
          .then(() => {})
          .catch(error => {
            this.$refs.registerSecondForm.setErrors(error.response.data.data)
          })
      }
    },
    id_number(newIdNumber) {
      if (this.member_type === 1) {
        this.$http
          .post('/membership/auth/registration/id-number/check', {
            id_number: newIdNumber,
          })
          .then(() => {})
          .catch(error => {
            this.$refs.registerSecondForm.setErrors(error.response.data.data)
          })
      }
    },
    business_registration_number(newBusinessRegistrationNumber) {
      if (this.member_type === 2) {
        this.$http
          .post(
            '/membership/auth/registration/business-registration-number/check',
            { business_registration_number: newBusinessRegistrationNumber },
          )
          .then(() => {})
          .catch(error => {
            this.$refs.registerSecondForm.setErrors(error.response.data.data)
          })
      }
    },
    // is_performer(performerValue) {
    //   if (performerValue === 'true') {
    //     this.show_stage_name_input = true
    //   } else {
    //     this.show_stage_name_input = false
    //   }
    // },
    id_file(newId) {
      if (newId.size > 5000000) {
        this.show_id_file_too_large_error = true
      } else {
        this.show_id_file_too_large_error = false
      }
    },
    child_birth_certificate(newCert) {
      if (newCert.size > 5000000) {
        this.show_child_certificate_too_large_error = true
      } else {
        this.show_child_certificate_too_large_error = false
      }
    },
    deceased_death_certificate(newCert) {
      if (newCert.size > 5000000) {
        this.show_death_certificate_too_large_error = true
      } else {
        this.show_death_certificate_too_large_error = false
      }
    },
    deceased_letter_of_administration(newCert) {
      if (newCert.size > 5000000) {
        this.show_letter_of_administration_too_large_error = true
      } else {
        this.show_letter_of_administration_too_large_error = false
      }
    },
    deceased_succession_letter(newCert) {
      if (newCert.size > 5000000) {
        this.show_succession_letter_too_large_error = true
      } else {
        this.show_succession_letter_too_large_error = false
      }
    },
  },
  async mounted() {
    this.content_types = [
      { text: 'Audiovisual/Dramatic Works', value: 'audio-visual-dramatic-works' },
      { text: 'Music Rights', value: 'music-rights' },
    ]
    await this.$http
      .get('/membership/content/roles')
      .then(response => {
        response.data.data.forEach(type => {
          this.member_role_types.push({ text: type.name, value: type.name })
        })
      })
      .catch(error => {
        console.log(error)
      })
    await this.$http
      .get('/membership/member/types')
      .then(response => {
        response.data.data.forEach(type => {
          this.member_types_all.push({ text: type.name, value: type.id })
          if (type.name !== 'Deceased Person') {
            this.member_types.push({ text: type.name, value: type.id })
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    updateContentTypeSelection(e) {
      this.content_type = e
      if (e === 'audio-visual-dramatic-works') {
        this.content_roles = [
          { text: 'Producer', value: 'producer' },
          { text: 'Actor/Actress', value: 'performer' },
          { text: 'Scriptwriter', value: 'scriptwriter' },
        ]
      }
      if (e === 'music-rights') {
        this.content_roles = [
          { text: 'Producer', value: 'producer' },
          { text: 'Performer', value: 'performer' },
          { text: 'Author', value: 'author' },
          { text: 'Composer/Arranger', value: 'composer' },
          { text: 'Publisher', value: 'publisher' },
        ]
      }
    },
    updateContentRoleSelection(e) {
      this.member_role_type = e
      this.member_types = []
      if (this.content_type === 'audio-visual-dramatic-works') {
        this.selected_content_type = 'Audio-Visual/Dramatic Works'
        if (e === 'producer') {
          this.selected_content_role = 'Producer'
          // eslint-disable-next-line camelcase
          this.member_types_all.forEach(member_type => {
            // eslint-disable-next-line camelcase
            if (member_type.text === 'Company') {
              this.member_types.push({ text: member_type.text, value: member_type.value })
            }
          })
        } else {
          if (e === 'performer') {
            this.selected_content_role = 'Actor/Actress'
          }
          if (e === 'scriptwriter') {
            this.selected_content_role = 'Scriptwriter'
          }
          // eslint-disable-next-line camelcase
          this.member_types_all.forEach(member_type => {
            // eslint-disable-next-line camelcase
            if (member_type.text !== 'Corporate/Record Label' && member_type.text !== 'Company') {
              this.member_types.push({ text: member_type.text, value: member_type.value })
            }
          })
        }
      }
      if (this.content_type === 'music-rights') {
        this.selected_content_type = 'Music Rights'
        if (e === 'publisher') {
          this.selected_content_role = 'Publisher'
          // eslint-disable-next-line camelcase
          this.member_types_all.forEach(member_type => {
            // eslint-disable-next-line camelcase
            if (member_type.text === 'Company') {
              this.member_types.push({ text: member_type.text, value: member_type.value })
            }
          })
        } else if (e === 'author' || e === 'composer') {
          if (e === 'author') {
            this.selected_content_role = 'Author'
          }
          if (e === 'composer') {
            this.selected_content_role = 'Composer/Arranger'
          }
          // eslint-disable-next-line camelcase
          this.member_types_all.forEach(member_type => {
            // eslint-disable-next-line camelcase
            if (member_type.text !== 'Corporate/Record Label' && member_type.text !== 'Company') {
              this.member_types.push({ text: member_type.text, value: member_type.value })
            }
          })
        } else if (e === 'performer') {
          this.selected_content_role = 'Performer'
          // eslint-disable-next-line camelcase
          this.member_types_all.forEach(member_type => {
            // eslint-disable-next-line camelcase
            if (member_type.text !== 'Corporate/Record Label' && member_type.text !== 'Company') {
              this.member_types.push({ text: member_type.text, value: member_type.value })
            }
          })
        } else if (e === 'producer') {
          this.selected_content_role = 'Producer'
          // eslint-disable-next-line camelcase
          this.member_types_all.forEach(member_type => {
            // eslint-disable-next-line camelcase
            if (member_type.text === 'Company') {
              this.member_types.push({ text: member_type.text, value: member_type.value })
            }
          })
        } else {
          // eslint-disable-next-line camelcase
          this.member_types_all.forEach(member_type => {
            // eslint-disable-next-line camelcase
            this.member_types.push({ text: member_type.text, value: member_type.value })
          })
        }
      }
    },
    updateMemberRoleSelection() {
      // const roleType = ['Producer', 'Author/Composer']
      // const performers = ['Performer(Musician)', 'Performer(Actor)']
      // this.member_types = []
      // if (roleType.includes(e)) {
      //   this.member_types_all.forEach(all => {
      //     this.member_types.push({ text: all.text, value: all.value })
      //   })
      // } else {
      //   this.member_types_all.forEach(all => {
      //     this.member_types.push({ text: all.text, value: all.value })
      //   })
      //   const index = this.member_types.findIndex(item => item.text === 'Deceased Person')
      //   if (index !== -1) {
      //     this.member_types.splice(index, 1)
      //   }
      // }
      // this.is_performer = performers.includes(e)
      // this.show_stage_name_input = !!this.is_performer
      // this.updatePerformerSelection()
    },
    updatePerformerSelection() {
      if (this.is_performer && this.member_type_in_words === 'Minor') {
        this.labels.stage_name = "Enter the minor's stage name"
      } else if (
        this.is_performer
        && this.member_type_in_words === 'Deceased Person'
      ) {
        this.labels.stage_name = "Enter the deceased person's stage name"
      } else {
        this.labels.stage_name = 'Enter your stage name'
      }
    },
    updateMemberTypeSelection(e) {
      this.member_type_in_words = this.member_types.find(
        type => type.value === e,
      ).text
      if (this.member_type_in_words === 'Corporate/Record Label') {
        this.partners_counter = 0
        this.partners_counter += 1
        this.total_pages = 4
        this.labels.passport_photo = 'Upload Your Logo'
      } else if (this.member_type_in_words === 'Company') {
        this.partners_counter = 0
        this.partners_counter += 1
        this.total_pages = 4
        this.labels.passport_photo = 'Upload Your Logo'
      } else if (this.member_type_in_words === 'Minor') {
        this.partners_counter = 0
        this.total_pages = 3
        this.labels.passport_photo = "Upload Minor's Passport Photo"
      } else if (this.member_type_in_words === 'Deceased Person') {
        this.partners_counter = 0
        this.total_pages = 3
        this.labels.passport_photo = "Upload Deceased Person's Passport Photo"
      } else {
        this.partners_counter = 0
        this.total_pages = 3
        this.labels.passport_photo = 'Upload Your Passport Photo'
      }
      this.updatePerformerSelection()
    },
    addDirector() {
      this.partners_counter += 1
    },
    validationFirstForm() {
      this.isLoading = true
      this.$refs.registerFirstForm.validate().then(success => {
        if (success) {
          if (!this.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Please accept out Terms & Conditions',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            this.isLoading = false
            return
          }
          this.current_page += 1
          this.isLoading = false
        }
      })
    },
    goToPrevPage() {
      if (this.current_page !== 1) {
        this.current_page -= 1
      }
    },
    validationSecondForm() {
      this.isLoading = true
      this.$refs.registerSecondForm.validate().then(success => {
        if (success) {
          this.current_page += 1
          this.isLoading = false
        }
      })
    },
    validationThirdForm() {
      this.isLoading = true
      this.$refs.registerThirdForm.validate().then(success => {
        if (success) {
          if (this.total_pages === 4) {
            this.current_page += 1
            this.isLoading = false
          } else {
            this.validationForm()
            this.isLoading = false
          }
        }
      })
    },
    validationForm() {
      this.isLoading = true
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.partners_counter; index++) {
        this.partners[index] = {
          first_name: this.partners_first_name[index],
          last_name: this.partners_last_name[index],
          id_number: this.partners_id_number[index],
          email: this.partners_email[index],
          phone_number: this.partners_phone_number[index],
          shares: this.partners_shares[index],
        }
      }
      const formData = new FormData()
      formData.append('member_role', this.member_role_type)
      formData.append('member_type', this.member_type)
      formData.append('id_number', this.id_number)
      formData.append('surname', this.surname)
      formData.append('other_names', this.other_names)
      formData.append('gender', this.member_gender)
      formData.append('business_name', this.business_name)
      formData.append(
        'business_registration_number',
        this.business_registration_number,
      )
      formData.append('email', this.regEmail)
      formData.append('phone_number', this.phone_number)
      formData.append('kra_pin', this.kra_pin)
      formData.append('age', this.age)
      formData.append('child_name', this.child_name)
      formData.append('child_relationship', this.child_relationship)
      formData.append('child_birth_certificate', this.child_birth_certificate)
      formData.append('password', this.password)
      formData.append('password_confirmation', this.password_confirmation)
      formData.append('partners', JSON.stringify(this.partners))
      formData.append('avatar', this.avatar)
      formData.append('kra_certificate', this.kra_certificate)
      formData.append('business_registration_certificate', this.business_registration_certificate)
      formData.append('id_file', this.id_file)
      formData.append('deceased_person_name', this.deceased_person_name)
      formData.append('death_certificate', this.deceased_death_certificate)
      formData.append(
        'letter_of_administration',
        this.deceased_letter_of_administration,
      )
      formData.append('stage_name', this.stage_name)
      formData.append('succession_letter', this.deceased_succession_letter)

      useJwt
        .register(formData)
        .then(response => {
          this.$router
            .replace({
              name: 'auth-verify-email',
              params: { id: response.data.tracking_number },
            })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Welcome ${response.data.name}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              })
            })
        })
        .catch(error => {
          this.$refs.registerFirstForm.setErrors(error.response.data)
          this.$refs.registerSecondForm.setErrors(error.response.data)
          this.$refs.registerThirdForm.setErrors(error.response.data)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Confirm entered data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    nth(n) {
      // eslint-disable-next-line no-mixed-operators
      return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th'
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped>
.brand-img img {
  width: 30%;
  margin: 190px 0 0 80px;
}
@media only screen and (min-width: 998px) {
  .brand-img img {
    position: fixed;
  }
}
@media only screen and (max-width: 998px) {
  .brand-logo {
    display: none;
  }
  .brand-text {
    display: none;
  }
  .brand-img {
    position: relative;
    margin-left: 240px;
    margin-top: -50px;
  }
  .brand-img img {
    width: 90%;
    margin: 80px 40px -460px -90px;
  }
}
@media only screen and (max-width: 570px) {
  .brand-logo {
    display: none;
  }
  .brand-img {
    position: relative;
    margin-left: 150px;
    margin-top: -50px;
  }
  .brand-img img {
    width: 90%;
    margin: 80px 40px -160px -30px;
  }
  .card-header-section {
    display: flex;
    flex-direction: column;
  }
  .card-header-section span {
    margin-top: 5px;
  }
}
@media only screen and (max-width: 450px) {
  .brand-logo {
    display: none;
  }
  .brand-img {
    position: relative;
    margin-left: 90px;
    margin-top: -50px;
  }
}
</style>
